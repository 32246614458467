<template>
  <b-card-code title="جدول الكورسات و الورشات" no-body>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <!-- filter -->
        <b-form-group
          label="فلتر"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="md"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="ابحث عن .."
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                الغاء الفلتر
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                v-b-modal.model-a-w
              >
                <feather-icon icon="FilePlusIcon" class="mr-50" />
                <span class="align-middle">اضافة كورس جديد</span>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>
<div class="demo-vertical-spacing">
      <b-progress
      v-if="precentage !== 0"
        :value="precentage"
        max="100"
      />
      </div>
    <b-table
      ref="table"
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :items="items"
      :fields="fields"
    >
      <template #cell(action)="action">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-success"
              v-b-modal.model-e-w
              @click="eRM(action.item)"
            >
              <feather-icon icon="EditIcon" class="mr-50" />
            </b-button>
          </b-col>
          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              v-b-modal.modal-center
              @click="dRM(action.item)"
            >
              <feather-icon icon="Trash2Icon" class="mr-50" />
            </b-button>
          </b-col>
        </b-dropdown>
      </template>
      <template #cell(link)="link">
        <b-button
          v-ripple.400="'rgba(0, 207, 232, 0.15)'"
          variant="outline-info"
          @click="onClicked(link.item.id)"
        >
          Link
        </b-button>
      </template>
      <template #cell(image)="image">
        <b-avatar size="lg" :src="getImage(image.item.image)" />
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          @change="check()"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
        @change="check()"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>
    <b-modal
      id="model-e-w"
      ok-title="حفظ التعديلات"
      centered
      size="lg"
      title="تعديل الكورس"
      @show="checkModel"
      @ok="updateResearch"
      @hidden="rEF"
    >
      <b-form novalidate class="needs-validation">
        <b-form-row>
          <b-col md="12">
            <label for="input-valid1">العنوان بالعربي:</label>
            <b-form-input
              id="input-valid1"
              v-model="editform.ar_name"
              :state="editform.ar_name.length > 0"
              placeholder="العنوان بالعربي"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">العنوان بالانكليزي:</label>
            <b-form-input
              id="input-valid1"
              v-model="editform.en_name"
              :state="editform.en_name.length > 0"
              placeholder="العنوان بالانكليزي"
            />
          </b-col>

          <b-col md="12">
            <label for="input-invalid1">سعر الكورس:</label>
            <b-form-input
              id="input-invalid1"
              v-model="editform.price"
              :state="editform.price.length > 0"
              placeholder="سعر الكورس"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">تفاصيل باللغة العربية:</label>
            <b-form-input
              id="input-valid1"
              v-model="editform.ar_description"
              :state="editform.ar_description.length > 0"
              placeholder="تفاصيل باللغة العربية"
            />
          </b-col>
          <b-col md="12">
            <label for="input-invalid2">تفاصيل باللغة الانكليزي:</label>
            <b-form-input
              id="input-invalid2"
              v-model="editform.en_description"
              :state="editform.en_description.length > 0"
              placeholder="تفاصيل باللغة الانكليزي"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">اختر صورة:</label>
            <b-form-file
            placeholder="اختر الملف او اسقطها هنا..."
            drop-placeholder="اختر الملف او اسقطها هنا..."
              v-model="editform.file"
              @change="selectFileForEditForm"
            />
          </b-col>
          <b-form-group label="">
          <div class="demo-inline-spacing">
      <b-form-checkbox
        v-model="editform.is_sustainabilty"
        value="1"
        @change="checkbox"
      >
        له علاقة بالتنمية المستدامة
      </b-form-checkbox>
      <b-form-checkbox
        v-model="editform.is_energy"
        value="1"
      >
        له علاقة بالطاقة المستدامة
      </b-form-checkbox>
    </div>
          </b-form-group>
        </b-form-row>
      </b-form>
    </b-modal>
    <b-modal
      id="model-a-w"
      ok-title="حفظ البيانات"
      centered
      size="lg"
      title="اضافة كورس جديد"
      @show="checkModel"
      @ok="aNR"
      @hidden="rF"
    >
      <b-form novalidate class="needs-validation">
        <b-form-row>
          <b-col md="12">
            <label for="input-valid1">العنوان بالعربي:</label>
            <b-form-input
              id="input-valid1"
              v-model="form.ar_name"
              :state="form.ar_name.length > 0"
              placeholder="العنوان بالعربي"
            />
          </b-col>

          <b-col md="12">
            <label for="input-invalid1">العنوان بالانكليزي:</label>
            <b-form-input
              id="input-invalid1"
              v-model="form.en_name"
              :state="form.en_name.length > 0"
              placeholder="العنوان بالانكليزي"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">سعر الكورس:</label>
            <b-form-input
              id="input-valid1"
              v-model="form.price"
              :state="form.price.length > 0"
              placeholder="price"
            />
          </b-col>
          <b-col md="12">
            <label for="input-invalid2">تفاصيل باللغة العربية:</label>
            <b-form-input
              id="input-invalid2"
              v-model="form.ar_description"
              :state="form.ar_description.length > 0"
              placeholder="تفاصيل باللغة العربية"
            />
          </b-col>
          <b-col md="12">
            <label for="input-invalid2">تفاصيل باللغة الانكليزي:</label>
            <b-form-input
              id="input-invalid2"
              v-model="form.en_description"
              :state="form.en_description.length > 0"
              placeholder="تفاصيل باللغة الانكليزي"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">اختر صورة:</label>
            <b-form-file
            placeholder="اختر الملف او اسقطها هنا..."
            drop-placeholder="اختر الملف او اسقطها هنا..."
              v-model="form.file"
              @change="selectFileForForm"
            />
          </b-col>
          <b-form-group label="">
          <div class="demo-inline-spacing">
      <b-form-checkbox
        v-model="form.is_sustainabilty"
        value="1"
      >
        له علاقة بالتنمية المستدامة
      </b-form-checkbox>
      <b-form-checkbox
        v-model="form.is_energy"
        value="1"
      >
      له علاقة بالطاقة المستدامة
      </b-form-checkbox>
    </div>
          </b-form-group>
          
        </b-form-row>
      </b-form>
    </b-modal>
    <b-modal
      id="modal-center"
      centered
      title="حذف كورس"
      @ok="deleteResearch"
      ok-title="حذف الحقل"
    >
      <b-card-text>
        هل تريد حذف هذه الحقل  " {{ this.editform.ar_name }} " ?
      </b-card-text>
    </b-modal>
  </b-card-code>
</template>

<script>
import store from "@/store";
import { filesUrl } from "@/main.js";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BAvatar,
  BModal,
  BDropdown,
  BDropdownItem,
  BFormCheckboxGroup,
  BForm,
  BFormRow,
  BCol,
  VBModal,
  BBadge,
  BFormValidFeedback,
  BFormInvalidFeedback,BProgress,
  BFormGroup,
  BFormCheckbox,
  BCardText,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BFormFile,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCardCode,
    BTable,
    BForm,
    BAvatar,
    BBadge,
    BFormRow,
    BDropdown,
  BFormFile,
    BDropdownItem,
    BFormGroup,
    BFormSelect,filesUrl,
    BPagination,
    BInputGroup,BProgress,
    BFormCheckboxGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BModal,
    VBModal,
    Ripple,
    BCol,
    BFormCheckbox,
    BCardText,
    BFormValidFeedback,
    BFormInvalidFeedback,
  },
  data() {
    return {
      precentage:0,
      teacher_id: "",
      perPage: 3,
      pageOptions: [3, 5, 10, 100],
      totalRows: 1,
      currentPage: 0,
      filter: null,
      filterOn: [],
      fields: [
        {
          key: "ar_name",
          label: "العنوان بالعربي",
        },
        {
          key: "en_name",
          label: "العنوان بالانكليزي",
        },
        {
          key: "price",
          label: "السعر",
        },
        { key: "image", label: "الصورة " },
        { key: "action", label: "الاجراءات" },
      ],
      items: [],
      form: {
        ar_name: "",
        en_name: "",
        ar_description: "",
        en_description: "",
        price: "",
        file: "",
        status: "",
        is_sustainabilty: 0,
        is_energy: 0,
      },
      editform: {
        ar_name: "",
        en_name: "",
        price: "",
        file: "",
        ar_description: "",
        en_description: "",
        status: "",
        is_sustainabilty: 0,
        is_energy: 0,
        id: null,
      },
      selected: [],
    };
  },
  watch: {
    filter: function (val) {
      if (val == null) {
        this.filter = null;
      }
      this.gS();
    },
  },
  computed: {},
  directives: {
    Ripple,
  },
  mounted() {
    this.ID(), this.gR();
  },
  methods: {
    checkModel() {
      this.selected = [];
      if (this.editform.is_sustainabilty == 1) {
        this.selected.push("is_sustainabilty");
      }
      if (this.editform.is_energy == 1) {
        this.selected.push("is_energy");
      }
    },
    checkbox(e){
        console.log(e)
        console.log(this.editform.is_sustainabilty)
    },
    resetCheckboxes() {
      this.selected = [];
    },
    async updateResearch() {
        let data = new FormData();
      data.append("ar_name", this.editform.ar_name);
      data.append("en_name", this.editform.en_name);
      data.append("ar_description", this.editform.ar_description);
      data.append("en_description", this.editform.en_description);
      data.append("price", this.editform.price);
      data.append("image", this.editform.file);
      data.append("is_sustainabilty", this.editform.is_sustainabilty);
      data.append("is_energy", this.editform.is_energy);
      await this.axios
        .post(`course/${this.editform.id}`, data,{
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gR();
            this.makeToast(
              "success",
              "update Success",
              "Your update is done successfully"
            );
          }
          this.rEF();
          this.precentage=0
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
          this.rEF();
        });
    },
    rF() {
      (this.form.ar_name = ""),
        (this.form.en_name = ""),
        (this.form.ar_description = ""),
      (this.form.price = ""),
        (this.form.file = ""),
        (this.form.is_sustainabilty = ""),
        (this.form.is_energy = ""),
        (this.form.en_description = "");
    },
    rEF() {
      (this.editform.ar_name = ""),
        (this.editform.en_name = ""),
        (this.editform.ar_description = ""),
      (this.editform.price = ""),
        (this.editform.file = ""),
        (this.editform.is_sustainabilty = ""),
        (this.editform.is_energy = ""),
        (this.editform.en_description = "");
      this.editform.id = "";
    },
    async aNR() {
        let data = new FormData();
      data.append("ar_name", this.form.ar_name);
      data.append("en_name", this.form.en_name);
      data.append("ar_description", this.form.ar_description);
      data.append("en_description", this.form.en_description);
      data.append("price", this.form.price);
      data.append("image", this.form.file);
      data.append("is_sustainabilty", this.form.is_sustainabilty);
      data.append("is_energy", this.form.is_energy);

      await this.axios
        .post(`course`, data,{
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gR();
            this.makeToast(
              "success",
              "update Success",
              "Your update is done successfully"
            );
          }
          this.rF();
          this.precentage=0
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
      this.rF();
    },
    async deleteResearch() {
      await this.axios
        .delete(`course/${this.editform.id}`)
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gR();
            this.makeToast(
              "success",
              "Delete Success",
              "Your item is deleted successfully"
            );
          }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    errorToast() {
      this.makeToast(
        "danger",
        "update failed",
        "some thing want wrong, i`m sorry :("
      );
    },
    eRM(item) {
        console.log(item)
      this.editform.ar_name = item.ar_name;
      this.editform.en_name = item.en_name;
      this.editform.ar_description = item.ar_description;
      this.editform.en_description = item.en_description;
      this.editform.price = item.price;
      this.editform.file = item.image;
      this.editform.is_sustainabilty = item.is_sustainabilty;
      this.editform.is_energy = item.is_energy;
      this.editform.id = item.id;
    },
    dRM(item) {
      this.editform.ar_name = item.ar_name;
      this.editform.id = item.id;
    },
    check() {
      setTimeout(() => this.gR(), 1000);
    },
    ID() {
      this.teacher_id = localStorage.getItem("NisourTeacherId");
    },
    getImage(path) {
      return `${filesUrl}/${path}`;
    },
    async gS() {
      await this.axios
        .get(
          `course?take=${this.perPage}&skip=${this.currentPage - 1}&title=${
            this.filter
          }`
        )
        .then((res) => {
          this.items = [];
          this.totalRows = res.data.totalCount;
          this.items = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gR() {
      await this.axios
        .get(`course?take=${this.perPage}&skip=${this.currentPage - 1}`)
        .then((res) => {
          this.items = [];
          this.totalRows = res.data.totalCount;
          this.items = res.data.items;
        })
        .catch((e) => {
          console.log(e);
          console.log("error");
        });
    },
    makeToast(variant = null, title = null, body = null) {
      this.$bvToast.toast(body, {
        title: title,
        variant,
        solid: false,
      });
    },
    selectFileForForm(e) {
      this.form.file = "";
      console.log(e.target.files);
      console.log(e.target.files[0]);
      this.form.file = e.target.files[0];
      console.log(this.form.files);
    },
    selectFileForEditForm(e) {
      this.editform.file = "";
      console.log(e.target.files);
      console.log(e.target.files[0]);
      this.editform.file = e.target.files[0];
      console.log(this.editform.file);
    },
  },
};
</script>
